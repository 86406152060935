import type { IframeHTMLAttributes } from "react";
import { useCallback, useState } from "react";
import { Loading } from "../Loading/Loading";
import { useEffect } from "react";

export type IPostMessageData = {
  type: string;
  [key: string]: unknown;
};

type IIframe = {
  height?: string;
  loadingMessage: string;
  loadingPadding?: number;
  postMessageHandler?: {
    messageType: string;
    onMessageReceived: (data: IPostMessageData) => void;
    messageOrigin?: string;
  };
  src: string;
  title: string;
  width?: string;
  padding?: number;
} & Pick<IframeHTMLAttributes<HTMLIFrameElement>, "allow">;

/**
 * @param loadingPadding Padding for the loading spinner. Used when the IFrame content handles it's own padding and the loading spinner has no other way to match that padding.
 */
export const Iframe = ({
  height = "100%",
  loadingMessage,
  loadingPadding,
  postMessageHandler,
  src,
  title,
  width = "100%",
  padding = 0,
  ...props
}: IIframe) => {
  const [iframeHasLoaded, setIframeHasLoaded] = useState(false);

  // Use a ref callback for React to be in the know of the iframe's properties
  // https://julesblom.com/writing/ref-callback-use-cases
  const iframeCallbackRef = useCallback(
    (instance: HTMLIFrameElement | null) => {
      if (instance) {
        instance.onload = () => {
          setIframeHasLoaded(true);
        };
      }
    },
    [],
  );

  usePostMessage({
    activateMessageHandler: postMessageHandler !== undefined,
    messageType: postMessageHandler?.messageType,
    onMessageReceived: postMessageHandler?.onMessageReceived,
    messageOrigin: postMessageHandler?.messageOrigin || src,
  });

  return (
    <>
      {!iframeHasLoaded && (
        <Loading message={loadingMessage} padding={loadingPadding ?? padding} />
      )}
      <iframe
        ref={iframeCallbackRef}
        style={{
          border: "none",
          height,
          width,
          padding: padding,
        }}
        title={title}
        src={src}
        {...props}
      />
    </>
  );
};

const usePostMessage = ({
  activateMessageHandler,
  messageType,
  onMessageReceived,
  messageOrigin,
}: {
  activateMessageHandler: boolean;
  messageType?: string;
  onMessageReceived?: (data: IPostMessageData) => void;
  messageOrigin: string;
}) => {
  useEffect(() => {
    if (!activateMessageHandler) {
      return;
    }

    const handleMessage = (event: MessageEvent) => {
      if (event.data.type !== messageType) {
        return;
      }
      if (!messageOrigin.startsWith(event.origin)) {
        return;
      }
      if (!onMessageReceived) {
        return;
      }
      onMessageReceived(event.data);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [activateMessageHandler, messageOrigin, messageType, onMessageReceived]);
};
