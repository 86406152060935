import { logisticsApi } from "./apiClient";
import { useQuery } from "@tanstack/react-query";
import type { IActivityOccurrenceOrGroupWithPatientId } from "@models/activities";
import {
  activityOccurrencesAndGroupsWithPatientIdsSchema,
  categorySchema,
  isGroup,
  timeOfDaySchema,
} from "@models/activities";
import { addDays, isSameDay, startOfToday } from "date-fns";
import { usePatient } from "./patient";
import { generateQueryString } from "@/api/Helpers";
import { format } from "@models/date-and-time";

export const scheduleKeys = {
  all: ["schedule"] as const,
};

const fetchMyActivityOccurrencesAndGroups = async () => {
  const activityOccurrencesAndGroupsResponse = await logisticsApi.get(
    `/occurrences${generateQueryString({
      category: [
        categorySchema.Values.HomeVisit,
        categorySchema.Values.VideoCall,
        categorySchema.Values.PatientTask,
        categorySchema.Values.PatientMeasurementTask,
      ],
      status: ["active", "closed"],
      to: format(addDays(startOfToday(), 2), "yyyy-MM-dd"),
    })}`,
  );
  return activityOccurrencesAndGroupsWithPatientIdsSchema.parse(
    activityOccurrencesAndGroupsResponse.data,
  );
};

export const getStatus = (
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroupWithPatientId,
) => {
  if (!isGroup(activityOccurrenceOrGroup)) {
    const activityOccurrence = activityOccurrenceOrGroup;
    return activityOccurrence.status;
  }
  const group = activityOccurrenceOrGroup;
  return group.visitStatus;
};

const fetchMySchedule = async () => {
  const activityOccurrencesAndGroups =
    await fetchMyActivityOccurrencesAndGroups();

  // SORTING
  const sortedActivityOccurrencesAndGroups =
    activityOccurrencesAndGroups.toSorted((a, b) => {
      // Finished activityOccurrencesAndGroups should be displayed first, if they are on the same day
      if (
        getStatus(a) === "finished" &&
        getStatus(b) !== "finished" &&
        isSameDay(a.start, b.start)
      ) {
        return -1;
      }
      if (
        getStatus(b) === "finished" &&
        getStatus(a) !== "finished" &&
        isSameDay(a.start, b.start)
      ) {
        return 1;
      }
      // Sort any time of day activities last
      if (
        a.timeOfDay === timeOfDaySchema.Values.Any &&
        b.timeOfDay !== timeOfDaySchema.Values.Any &&
        isSameDay(a.start, b.start)
      ) {
        return 1;
      }
      if (
        b.timeOfDay === timeOfDaySchema.Values.Any &&
        a.timeOfDay !== timeOfDaySchema.Values.Any &&
        isSameDay(a.start, b.start)
      ) {
        return -1;
      }

      if (a.start < b.start) {
        return -1;
      }
      if (a.start > b.start) {
        return 1;
      }
      return 0;
    });

  // GROUPING
  const groupedSchedule = sortedActivityOccurrencesAndGroups.reduce(
    (groups, item) => {
      const lastGroup = groups[groups.length - 1];
      if (!lastGroup) {
        return groups;
      }
      if (lastGroup.length === 0) {
        lastGroup.push(item);
        return groups;
      }
      const lastItem = lastGroup[lastGroup.length - 1];
      if (!lastItem) {
        return groups;
      }
      if (lastItem.start.getDate() === item.start.getDate()) {
        lastGroup.push(item);
        return groups;
      }
      groups.push([item]);
      return groups;
    },
    [[]] as (typeof sortedActivityOccurrencesAndGroups)[],
  );

  return groupedSchedule;
};

const ONE_MINUTE = 60000;
export const useMySchedule = () => {
  const { data: patient } = usePatient();

  return useQuery({
    enabled: patient !== undefined,
    queryKey: scheduleKeys.all,
    queryFn: () => fetchMySchedule(),
    // Activity occurrences have time based status logic, so we need to refetch them often where status matters/is shown.
    refetchInterval: ONE_MINUTE,
  });
};
