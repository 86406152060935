import type { MatrixClient, SyncState } from "matrix-js-sdk";
import { ClientEvent } from "matrix-js-sdk";
import { useEffect, useState } from "react";

// https://github.com/matrix-org/matrix-js-sdk/blob/develop/src/client.ts#L964
export const useMatrixConnectionStatus = (
  matrixClient: MatrixClient | null,
) => {
  const [connectionStatus, setConnectionStatus] = useState<SyncState | null>(
    null,
  );

  useEffect(() => {
    if (!matrixClient) return;
    const updateConnectionStatus = (state: SyncState) => {
      setConnectionStatus(state);
    };
    matrixClient.on(ClientEvent.Sync, updateConnectionStatus);
    return () => {
      matrixClient.off(ClientEvent.Sync, updateConnectionStatus);
    };
  });

  return connectionStatus;
};
