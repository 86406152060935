import React from "react";
import clsx from "clsx";
import styles from "./Text.module.scss";

export type IText = React.HTMLAttributes<
  HTMLParagraphElement | HTMLSpanElement | HTMLDivElement
> & {
  color?: "default" | "faded";
  element: "p" | "span" | "div";
  size?: "base" | "small" | "large";
  weight?: "regular" | "medium" | "bold";
};

export const Text = ({
  children,
  color = "default",
  element,
  size = "base",
  weight = "regular",
  ...props
}: IText) => {
  const htmlElement = element;

  const TextElement = ({
    ...props
  }: React.HTMLAttributes<
    HTMLParagraphElement | HTMLSpanElement | HTMLDivElement
  >) => React.createElement(htmlElement, props, children);

  return (
    <TextElement
      {...props}
      className={clsx(
        styles[`color-${color}`],
        styles[`element-${element}`],
        styles[`size-${size}`],
        styles[`weight-${weight}`],
        props.className,
      )}
    >
      {children}
    </TextElement>
  );
};
