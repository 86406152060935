import styles from "./Loading.module.scss";
import { Spinner } from "../Spinner/Spinner";

type ILoading = {
  message?: string;
  withSpinner?: boolean;
  padding?: number;
};

/**
 * Use **padding** sparingly, prefer to use padding of parent (layout) component
 */
export const Loading = ({
  message,
  withSpinner = true,
  padding = 0,
}: ILoading) => {
  return (
    <div className={styles.content} style={{ padding: padding }}>
      {withSpinner ? <Spinner /> : null}
      {message !== undefined ? <p>{`${message}...`}</p> : null}
    </div>
  );
};

type ILoadingOverlay = ILoading & {
  children: React.ReactNode;
  show: boolean;
};

export const LoadingOverlay = ({
  message,
  withSpinner = true,
  children,
  show,
}: ILoadingOverlay) => {
  if (show) {
    return (
      <div className={styles.overlayWrapper}>
        {children}
        <div className={styles.overlay}>
          <Loading message={message} withSpinner={withSpinner} />
        </div>
      </div>
    );
  } else {
    return children;
  }
};
