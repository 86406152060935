import { z } from "zod";
// eslint-disable-next-line
import { format as defaultFormat } from "date-fns";
import { sv } from "date-fns/locale";

// Same as `format` from `date-fns` but with Swedish locale built-in
export const format: (
  ...args: Parameters<typeof defaultFormat>
) => ReturnType<typeof defaultFormat> = (...args) =>
  defaultFormat(args[0], args[1], {
    ...args[2],
    locale: sv,
  });

export const dateSchema = z.date().or(
  z
    .string()
    .datetime({ offset: true })
    .transform((isoDateAsString) => new Date(isoDateAsString)),
);
export type IDate = z.infer<typeof dateSchema>;

export const timestampSchema = dateSchema.nullish();
