import { t } from "@lingui/macro";
import styles from "./InputTextArea.module.scss";
import { useEffect } from "react";

type IInputTextArea = {
  rows?: number;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  autoResize?: boolean;
  inputTextAreaRef: React.RefObject<HTMLTextAreaElement>;
  placeholder?: string;
};

export const InputTextArea = ({
  rows = 1,
  value,
  onChange,
  onFocus,
  autoResize = true,
  inputTextAreaRef,
  placeholder = t`Skriv ett meddelande`,
}: IInputTextArea) => {
  useAutosizeTextArea(autoResize, inputTextAreaRef.current, value);

  return (
    <textarea
      ref={inputTextAreaRef}
      className={styles.inputTextArea}
      rows={rows}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
    />
  );
};

/**
 * Solution by https://medium.com/@oherterich/creating-a-textarea-with-dynamic-height-using-react-and-typescript-5ed2d78d9848
 *
 * Updates the height of a <textarea> when the value changes.
 * @param textAreaRef
 * @param value
 */
const useAutosizeTextArea = (
  autoResize: boolean,
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
) => {
  useEffect(() => {
    if (autoResize) {
      if (textAreaRef) {
        // We need to reset the height momentarily to get the correct scrollHeight for the textarea
        textAreaRef.style.height = "0px";
        const scrollHeight = textAreaRef.scrollHeight;

        // We then set the height directly, outside of the render loop
        // Trying to set this with state or a ref will produce an incorrect value.
        textAreaRef.style.height = scrollHeight + "px";
      }
    }
  }, [autoResize, textAreaRef, value]); // Value important for when message is sent, to shrink the textarea
};
