import styles from "./Chat.module.scss";
import { Message } from "./Message";
import { MessagePanel } from "./MessagePanel";
import { MessageGroup } from "./MessageGroup";
import { HeaderPanel } from "./HeaderPanel";
import { InfoMessage } from "./InfoMessage";
import { InfoPanel } from "./InfoPanel";
import { InputPanel } from "./InputPanel";
import { InputTextArea } from "./InputTextArea";
import { IsTyping } from "./IsTyping";
import { RetrieveHistoryButton } from "./RetrieveHistoryButton";
import { SendMessageButton } from "./SendMessageButton";

type IChat = {
  children: JSX.Element | JSX.Element[];
};

const Chat = ({ children }: IChat) => {
  return <div className={styles.chat}>{children}</div>;
};

Chat.Message = Message;
Chat.MessagePanel = MessagePanel;
Chat.MessageGroup = MessageGroup;
Chat.HeaderPanel = HeaderPanel;
Chat.InfoMessage = InfoMessage;
Chat.InfoPanel = InfoPanel;
Chat.InputPanel = InputPanel;
Chat.InputTextArea = InputTextArea;
Chat.IsTyping = IsTyping;
Chat.RetrieveHistoryButton = RetrieveHistoryButton;
Chat.SendMessageButton = SendMessageButton;

export default Chat;
