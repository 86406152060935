import { Heading } from "@components/Heading/Heading";
import { Text } from "@components/Text/Text";
import styles from "./cardHeading.module.scss";
import HouseIcon from "@components/icons/HouseIcon";
import VideoCameraIcon from "@components/icons/VideoCameraIcon";
import AvatarIcon from "@components/icons/AvatarIcon";
import { categorySchema, type IActivityCategory } from "@models/activities";
import HealthParametersIcon from "@components/icons/HealthParametersIcon";
import clsx from "clsx";

type ICardHeading = {
  category: IActivityCategory;
  title: string;
  formattedTimespan: string | null;
};

export const CardHeading = ({
  category,
  title,
  formattedTimespan,
}: ICardHeading) => {
  const renderIcon = () => {
    switch (category) {
      case categorySchema.Values.HomeVisit:
        return <HouseIcon />;
      case categorySchema.Values.VideoCall:
        return <VideoCameraIcon />;
      case categorySchema.Values.PatientTask:
        return <AvatarIcon />;
      case categorySchema.Values.PatientMeasurementTask:
        return <HealthParametersIcon />;
      default:
        return;
    }
  };
  return (
    <header className={styles.cardHeading}>
      <span className={clsx([styles.icon, styles[`category-${category}`]])}>
        {renderIcon()}
      </span>
      <div className={styles.text}>
        <Heading level="h2" weight="medium">
          {title}
        </Heading>
        {formattedTimespan ? (
          <Text element="span" size="small">
            {formattedTimespan}
          </Text>
        ) : null}
      </div>
    </header>
  );
};
