import { useEffect, useState } from "react";
import type { MatrixClient, MatrixEvent, Room } from "matrix-js-sdk";
import { RoomEvent } from "matrix-js-sdk";

// Probably don't use this outside of the MatrixClient context, to avoid too many listeners
export const useUnreadNotifications = (matrixClient: MatrixClient | null) => {
  const [unreadNotifications, setUnreadNotifications] = useState<
    Record<string, number> | undefined
  >();

  // Set up initial notification counts when matrixClient is known
  useEffect(() => {
    if (matrixClient === null) {
      return;
    }

    const rooms = matrixClient.getVisibleRooms();
    const initialUnreadNotifications = rooms.reduce(
      (accumulator, room) => ({
        ...accumulator,
        [room.roomId]: room.getUnreadNotificationCount(),
      }),
      {},
    );
    setUnreadNotifications(initialUnreadNotifications);
  }, [matrixClient]);

  // Set up listeners to update notification counts when events indicate that notification count has changed
  useEffect(() => {
    if (matrixClient === null) {
      return;
    }

    const incrementNotificationCount = (
      event: MatrixEvent,
      room: Room | undefined,
    ) =>
      setUnreadNotifications((prev) => {
        const { ...current } = prev;
        if (!room) return prev;
        if (event.getSender() !== matrixClient?.getUserId()) {
          current[room.roomId] = room.getUnreadNotificationCount();
        }
        return current;
      });

    const resetNotificationCount = (
      _event: MatrixEvent,
      room: Room | undefined,
    ) => {
      return setUnreadNotifications((prev) => {
        const { ...current } = prev;
        if (!room) return prev;
        // At this point, the count is either > 0 (we just received the other user's receipt),
        // or 0 (we just received our own receipt).
        // Either way, it fulfills our needs.
        // Also, `event.getSender()` returns `undefined` for receipt events, so we can't rely on that.
        current[room.roomId] = room.getUnreadNotificationCount();
        return current;
      });
    };

    matrixClient.on(RoomEvent.Timeline, incrementNotificationCount);
    matrixClient.on(RoomEvent.Receipt, resetNotificationCount);

    return () => {
      matrixClient.off(RoomEvent.Timeline, incrementNotificationCount);
      matrixClient.off(RoomEvent.Receipt, resetNotificationCount);
    };
  }, [matrixClient]);

  return unreadNotifications;
};
