import styles from "./UnreadMessageBannerContent.module.scss";
import { Text } from "@components/Text/Text";
import ChatConversationIcon from "@components/icons/ChatConversation";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import { Trans } from "@lingui/macro";

export const UnreadMessageBannerContent = ({
  hasUnreadNotification,
}: {
  hasUnreadNotification: boolean;
}) => {
  return hasUnreadNotification ? (
    <div className={styles.unreadMessageBannerContent}>
      <NotificationCircle isVisible>
        <ChatConversationIcon />
      </NotificationCircle>
      <Text element="div">
        <Trans>Du har ett oläst meddelanden i chatten</Trans>
      </Text>
      <Text element="div" weight="bold">
        <Trans>Visa</Trans>
      </Text>
    </div>
  ) : null;
};
