import styles from "./IsTyping.module.scss";

export const IsTyping = ({ text }: { text?: string | JSX.Element }) => {
  return (
    <div className={styles.isTyping}>
      {text}
      <div className={styles.dots}>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
      </div>
    </div>
  );
};
