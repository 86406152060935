import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useEffect } from "react";
import { FilledButton } from "@components/Button/Button";
import { Heading } from "@components/Heading/Heading";
import { Text } from "@components/Text/Text";
import styles from "./requireAuth.module.scss";
import { Trans } from "@lingui/macro";
import { t } from "@lingui/macro";

const DevicePairing = () => {
  const { signinSilent, isLoading } = useAuth();
  return (
    <div className={styles.devicePairing}>
      <Heading level="h1">
        <Trans>Koppla patient</Trans>
      </Heading>
      <Text element="p" size="small">
        <Trans>
          Koppla patienten via utrustningssidan och klicka sedan på knappen
          nedan för att bekräfta kopplingen.
        </Trans>
      </Text>
      <FilledButton disabled={isLoading} onClick={() => signinSilent()}>
        {isLoading ? t`Bekräftar koppling...` : t`Bekräfta koppling`}
      </FilledButton>
    </div>
  );
};

const RequireAuth = ({
  children,
}: {
  children: JSX.Element;
  acceptedRoles?: string[];
}) => {
  const { isAuthenticated, events, signinRedirect, user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    return events.addAccessTokenExpired(() => signinRedirect());
  }, [events, signinRedirect]);

  const loggedInAsDeviceOnly =
    isAuthenticated &&
    user?.profile.user_type === "device" &&
    user?.profile.role !== "Patient";

  if (loggedInAsDeviceOnly) {
    return <DevicePairing />;
  }

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
