import clsx from "clsx";
import styles from "./Message.module.scss";

export type IMessage = {
  message: string;
  type: "sent-by-me" | "sent-by-other" | "received";
};

export const Message = ({ message, type }: IMessage) => {
  return (
    <div className={clsx([styles.message, styles[`type-${type}`]])}>
      <p>{message}</p>
    </div>
  );
};
