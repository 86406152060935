import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { Navigate, useLocation } from "react-router-dom";
import { useCallback, useEffect } from "react";
import styles from "./login.module.scss";
import * as Sentry from "@sentry/react";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

const SCOPES = {
  accessLogisticsApiAsPatient: "logistic.api",
  accessPatientApiAsPatient: "patient.api",
  userProfile: "openid",
  // Custom scope required for auth service to differentiate between old patient/OTP-based login and new device-based login
  userType: "user_type",
};

export const STRINGIFIED_SCOPES = Object.values(SCOPES).join(" ");

export const Login = () => {
  const {
    isAuthenticated,
    activeNavigator,
    isLoading,
    signinRedirect,
    error,
    events,
    user,
  } = useAuth();
  const { state }: { state?: { from?: Location } } = useLocation();
  const { _ } = useLingui();
  const redirectURL =
    // If there is a state in the user object, it means that the user was redirected to the login page, and this is where they came from. Respect that firstly.
    // If there is a from in the state object, it means that the user was redirected internally, and this is where they came from. Respect that secondly.
    // Default to root.
    user?.state
      ? user?.state
      : state?.from
        ? `${state?.from?.pathname}${state?.from?.search}`
        : "/";

  const signin = useCallback(() => {
    signinRedirect({
      state: redirectURL,
    });
  }, [signinRedirect, redirectURL]);

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !isAuthenticated &&
      !activeNavigator &&
      !isLoading
    ) {
      signin();
    }
  }, [isAuthenticated, activeNavigator, isLoading, signin]);

  // Token can expire while the app is in background
  // Forcefully sign in again if this happens
  useEffect(() => {
    return events.addAccessTokenExpired(signin);
  }, [events, signin]);

  if (isAuthenticated) {
    return <Navigate to={redirectURL} replace />;
  }

  if (activeNavigator || isLoading) {
    return (
      <div className={styles.login}>
        <Loading message={_(msg`Loggar in`)} />
      </div>
    );
  }

  if (error) {
    Sentry.captureException(error);
    return (
      <div className={styles.login}>
        <ErrorMessage message={_(msg`Kunde inte logga in`)} />
      </div>
    );
  }

  return null;
};
