const DEFAULT_LANGUAGE = "en";

export const retrieveBrowserLocale = () => {
  // This corresponds to the user's top selected browser language.
  const browserLocale = navigator.language;

  // Users often have `en-US` or similar selected.
  if (browserLocale.startsWith("en")) {
    return "en";
  }
  if (browserLocale.startsWith("sv")) {
    return "sv";
  }
  if (browserLocale.startsWith("de")) {
    return "de";
  }
  return DEFAULT_LANGUAGE;
};
