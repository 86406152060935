import { forwardRef, useState } from "react";
import styles from "./MessagePanel.module.scss";
import { RetrieveHistoryButton } from "./RetrieveHistoryButton";
import { Trans } from "@lingui/macro";

type IMessagePanel = {
  children: JSX.Element[];
  getHistory: () => Promise<boolean>;
};

export const MessagePanel = forwardRef<HTMLDivElement | null, IMessagePanel>(
  ({ children, getHistory }, ref) => {
    const [mayHaveHistory, setMayHaveHistory] = useState(true);
    const getAndSetHistory = async () => {
      const hasRemainingHistory = await getHistory();
      setMayHaveHistory(hasRemainingHistory);
    };
    return (
      <div ref={ref} className={styles.messagePanel}>
        {mayHaveHistory ? (
          <RetrieveHistoryButton onClick={getAndSetHistory} />
        ) : (
          <p className={styles.noHistoryText}>
            <Trans>Det finns inga tidigare chattmeddelanden</Trans>
          </p>
        )}
        {children}
      </div>
    );
  },
);
MessagePanel.displayName = "MessagePanel";
