import { useContext, useEffect, useState } from "react";
import ChatContext from "./ChatContext";
import { useMatrixRoom, useMatrixRoomAliases } from "./useMatrixRoom";

const getRoomNameFromAlias = (alias: string) => {
  // We expect the alias to be of the form: #roomName:domain
  const roomName = alias.split("#")[1]?.split(":")[0];
  return roomName;
};

const useRoomNamesWithUnreadNotifications = () => {
  const { matrixClient, unreadNotifications } = useContext(ChatContext);
  const [roomIds, setRoomIds] = useState<string[]>([]);
  const roomAliases = useMatrixRoomAliases(matrixClient, roomIds);
  const unfilteredRoomNames = roomAliases.map((alias) =>
    getRoomNameFromAlias(alias),
  );
  const roomNames = unfilteredRoomNames.filter(
    (unfilteredRoomName) => unfilteredRoomName !== undefined,
  );

  useEffect(() => {
    if (!matrixClient || !unreadNotifications) return;

    const roomsWithNotifications = Object.entries(unreadNotifications).filter(
      ([_, count]) => count > 0,
    );

    setRoomIds(roomsWithNotifications.map(([roomId]) => roomId));
  }, [matrixClient, unreadNotifications]);

  return roomNames;
};

const useUnreadNotificationCounts = ({
  roomName,
}: {
  roomName: string | undefined;
}) => {
  const { matrixClient, unreadNotifications } = useContext(ChatContext);
  const roomAlias = `#${roomName}:${matrixClient?.getDomain()}`;
  const roomId = useMatrixRoom(matrixClient, roomAlias)?.roomId;
  if (!roomId) {
    return undefined;
  }

  return unreadNotifications?.[roomId];
};

const useHasUnreadNotifications = ({
  roomName,
}: {
  roomName: string | undefined;
}) => {
  const unreadNotificationCount = useUnreadNotificationCounts({ roomName });
  return unreadNotificationCount !== undefined && unreadNotificationCount >= 1;
};

export {
  useUnreadNotificationCounts,
  useHasUnreadNotifications,
  useRoomNamesWithUnreadNotifications,
};
