import type { IIconButton } from "../Button/Button";
import { IconButton } from "../Button/Button";
import ArrowUpFilledIcon from "../icons/ArrowUpFilledIcon";

type ISendMessageButton = Omit<IIconButton, "children">;

export const SendMessageButton = (props: ISendMessageButton) => {
  return (
    <IconButton variant="filled" {...props}>
      <ArrowUpFilledIcon />
    </IconButton>
  );
};
