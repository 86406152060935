import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { patientApi } from "./apiClient";
import type { IVideoService } from "@models/video";
import { generateQueryString } from "./Helpers";

const jitsiInfoSchema = z.object({
  token: z.string(),
  domain: z.string(),
  room: z.string(),
});
const compodiumInfoSchema = z.object({ meetingUrl: z.string() });
const acsInfoSchema = z.object({
  endpoint: z.string(),
  userId: z.string(),
  token: z.string(),
  groupId: z.string(),
});

const videoKeys = {
  all: ["video"] as const,
  info: (videoService: IVideoService) =>
    [...videoKeys.all, "info", videoService] as const,
};

export const useVideoInfo = (videoService: IVideoService) => {
  const path = "videocall";
  return useQuery({
    queryKey: videoKeys.info(videoService),
    queryFn: async () => {
      if (videoService === "Jitsi") {
        const queryParameters = generateQueryString({
          "video-service": "jitsi",
        });
        const url = `${path}${queryParameters}`;

        const response = await patientApi.post(url);
        return {
          ...jitsiInfoSchema.parse(response.data),
          videoService: "Jitsi" as const,
        };
      }
      if (videoService === "Compodium") {
        const queryParameters = generateQueryString({
          "video-service": "compodium",
        });
        const url = `${path}${queryParameters}`;

        const response = await patientApi.post(url);
        return {
          ...compodiumInfoSchema.parse(response.data),
          videoService: "Compodium" as const,
        };
      }
      if (videoService === "ACS") {
        const queryParameters = generateQueryString({
          "video-service": "acs",
        });
        const url = `${path}${queryParameters}`;

        const response = await patientApi.post(url);
        return {
          ...acsInfoSchema.parse(response.data),
          videoService: "ACS" as const,
        };
      }

      return null;
    },
    // Default (true). Set for clarity. Since we run video in the same window in Care we need to refetch on mount.
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};
