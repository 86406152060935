import { retrieveBrowserLocale } from "@/utils/i18n";
import { JitsiMeeting } from "@jitsi/react-sdk";
import type { IJitsiMeetingProps } from "@jitsi/react-sdk/lib/types";
import { useNavigate } from "react-router-dom";

type IJitsiVideo = Omit<IJitsiMeetingProps, "domain"> & { videoServer: string };

const defaultIframeRef = (iframeRef: { style: { height: string } }) => {
  iframeRef.style.height = "100%";
};

export const JitsiVideo = ({
  getIFrameRef = defaultIframeRef,
  ...props
}: IJitsiVideo) => {
  const navigate = useNavigate();
  return (
    <JitsiMeeting
      domain={props.videoServer}
      getIFrameRef={getIFrameRef}
      lang={retrieveBrowserLocale()} // Set UI language, overwriting built-in language detection: https://github.com/jitsi/jitsi-meet-react-sdk/issues/36
      // https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe-events/#readytoclose
      onReadyToClose={() => {
        navigate("/schedule");
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage("video:finished");
        }
      }}
      {...props}
    />
  );
};
