import CalendarIcon from "@components/icons/CalendarIcon";
import { usePatient } from "./api/patient";
import styles from "./menu.module.scss";
import { NavLink } from "react-router-dom";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import ChatConversationIcon from "@components/icons/ChatConversation";
import HealthParametersIcon from "@components/icons/HealthParametersIcon";
import { Heading } from "@components/Heading/Heading";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/featureFlags";
import MedicalBookIcon from "@components/icons/MedicalBookIcon";
import { Trans } from "@lingui/macro";

const LargeIcon = ({ children }: { children: JSX.Element }) => {
  return <span className={styles.largeIcon}>{children}</span>;
};

export const Menu = ({
  hasUnreadNotification,
}: {
  hasUnreadNotification: boolean;
}) => {
  const { data: patient } = usePatient();
  const { data: carePatientNote } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.CarePatientNote,
  );
  return (
    <aside className={styles.menu}>
      {patient ? (
        <Heading level="h2" className={styles.patientName}>
          {patient.name}
        </Heading>
      ) : null}

      <nav>
        <ul className={styles.linkList}>
          <li>
            <NavLink to="/schedule" className={styles.navigationLink}>
              <LargeIcon>
                <CalendarIcon />
              </LargeIcon>
              <Trans>Schema</Trans>
            </NavLink>
          </li>
          <li>
            <NavLink to="/measurements" className={styles.navigationLink}>
              <LargeIcon>
                <HealthParametersIcon />
              </LargeIcon>
              <Trans>Mätvärden</Trans>
            </NavLink>
          </li>
          <li>
            <NavLink to="/chat" className={styles.navigationLink}>
              <LargeIcon>
                <NotificationCircle isVisible={hasUnreadNotification}>
                  <ChatConversationIcon />
                </NotificationCircle>
              </LargeIcon>
              <Trans>Chatt</Trans>
            </NavLink>
          </li>
          {carePatientNote ? (
            <li>
              <NavLink to="/healthCare" className={styles.navigationLink}>
                <LargeIcon>
                  <MedicalBookIcon />
                </LargeIcon>
                <Trans>Din vård</Trans>
              </NavLink>
            </li>
          ) : null}
        </ul>
      </nav>
    </aside>
  );
};
