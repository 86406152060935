import { logisticsApi } from "./apiClient";
import { msg } from "@lingui/macro";

export const activityCategoryDictionary = {
  HomeVisit: msg`Hembesök`,
  VideoCall: msg`Videosamtal`,
  PatientTask: msg`Patientaktivitet`,
  AdminTask: msg`Administrativ uppgift`,
  PatientMeasurementTask: msg`Mätvärden`,
};

export const finishActivityOccurrence = async (
  activityId: string,
  occurrenceId: string,
) => {
  await logisticsApi.post(
    `activities/${activityId}/occurrences/${occurrenceId}/finish`,
  );
};
