import InformationIcon from "../icons/InformationIcon";
import styles from "./InfoMessage.module.scss";

type IInfoMessage = { title: string; description?: string };

export const InfoMessage = ({ title, description }: IInfoMessage) => {
  return (
    <div className={styles.infoMessage}>
      <div className={styles.icon}>
        <InformationIcon />
      </div>
      <div>
        <p className={styles.title}>{title}</p>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};
