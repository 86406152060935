const whitespaceOrHyphen = /[\s-]+/;

// \P{L} matches a single code point NOT in the category "letter".
const removeNonLetters = (word: string): string => {
  return word.replace(/\P{L}/gu, "");
};

const getFirstLetterOfName = (word: string): string => {
  if (!word[0]) {
    return "";
  }
  return word[0];
};

/**
 * Accepts a name (string) and returns the initials of the string.
 *
 * @param name
 * @returns The initials of a name. E.g. "John Doe" -> "JD", "John-Marcus Doe" -> "JMD", "John Marcus Doe" -> "JMD", "John" -> "J"
 */
export const getInitials = (name: string): string => {
  const initials = name
    .split(whitespaceOrHyphen)
    .map(removeNonLetters)
    .map(getFirstLetterOfName)
    .join("")
    .toUpperCase();
  return initials;
};
