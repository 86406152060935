import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { logisticsApi } from "./apiClient";

export const knownFeatureFlagsSchema = z.enum(["CarePatientNote"]);

const featureFlagSchema = z.object({
  name: knownFeatureFlagsSchema,
  isEnabled: z.boolean(),
});

export type IFeatureFlag = z.infer<typeof featureFlagSchema>;

const featureFlagKeys = {
  all: ["featureFlags"] as const,
  flag: (key: string) => ["featureFlag", key] as const,
};

async function getFeatureFlagStatus(key: string) {
  const featureFlag = await logisticsApi.get(`/features/${key}`);
  return featureFlagSchema.parse(featureFlag.data).isEnabled;
}

export const useFeatureFlag = (key: string) => {
  return useQuery({
    queryKey: featureFlagKeys.flag(key),
    queryFn: () => getFeatureFlagStatus(key),
  });
};
