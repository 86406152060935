import type React from "react";
import { forwardRef } from "react";
import clsx from "clsx";
import styles from "./Button.module.scss";
import { Trigger as DropDownTrigger } from "@radix-ui/react-dropdown-menu";
import { Trigger as PopoverTrigger } from "@radix-ui/react-popover";

/* BASE */

type IBaseButtonStyles = {
  color?: "primary" | "negative";
  variant?: "filled" | "outlined" | "plain";
  size?: "medium" | "small" | "large";
  width?: "hug-content" | "fill-container";
  weight?: "regular" | "light";
  contentType?: "text" | "icon" | "text-and-icon";
};

export type IBaseButton = IBaseButtonStyles &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children: string | JSX.Element | Array<string | JSX.Element>;
  };

const BaseButton = forwardRef<HTMLButtonElement, IBaseButton>(
  (
    {
      children,
      color = "primary",
      variant = "filled",
      width = "hug-content",
      size = "medium",
      weight = "regular",
      contentType = "text",
      type = "button",
      ...props
    }: IBaseButton,
    ref,
  ) => {
    return (
      <button
        className={clsx(
          styles.baseButton,
          styles[`color-${color}`],
          styles[`width-${width}`],
          styles[`size-${size}`],
          styles[`weight-${weight}`],
          styles[`variant-${variant}`],
          styles[`contentType-${contentType}`],
        )}
        type={type}
        ref={ref}
        {...props}
      >
        {children}
      </button>
    );
  },
);

BaseButton.displayName = "Button";
/* STANDARD BUTTONS */

export type IFilledButton = Omit<IBaseButton, "variant">;
export type IOutlinedButton = Omit<IBaseButton, "variant">;
export type IPlainButton = Omit<IBaseButton, "variant">;

export const FilledButton = (props: IFilledButton) => {
  return <BaseButton variant="filled" {...props} />;
};

export const OutlinedButton = (props: IOutlinedButton) => {
  return <BaseButton variant="outlined" {...props} />;
};

export const PlainButton = (props: IPlainButton) => {
  return <BaseButton variant="plain" {...props} />;
};

/* ICON BUTTON */

export interface IIconButton extends Omit<IBaseButton, "contentType"> {
  "aria-label": string;
}

export const IconButton = ({ variant = "plain", ...props }: IIconButton) => {
  return <BaseButton contentType="icon" variant={variant} {...props} />;
};

/**
 * DROPDOWN TRIGGER BUTTON
 *
 * This is a button that is used as a trigger for a dropdown menu: @/components/DropdownMenu/DropdownMenu.tsx
 *
 * @param param0
 * @returns
 */
export const DropDownTriggerButton = forwardRef<HTMLButtonElement, IBaseButton>(
  (
    {
      children,
      color = "primary",
      variant = "plain",
      width = "hug-content",
      size = "medium",
      weight = "regular",
      contentType = "text",
      type = "button",
      ...props
    }: IBaseButton,
    ref,
  ) => {
    return (
      <DropDownTrigger
        className={clsx(
          styles.baseButton,
          styles[`color-${color}`],
          styles[`width-${width}`],
          styles[`size-${size}`],
          styles[`weight-${weight}`],
          styles[`variant-${variant}`],
          styles[`contentType-${contentType}`],
        )}
        type={type}
        ref={ref}
        {...props}
      >
        {children}
      </DropDownTrigger>
    );
  },
);

DropDownTriggerButton.displayName = "Button";

/**
 * POPOVER TRIGGER BUTTON
 *
 * This is a button that is used as a trigger for a Popover Trigger: @/components/Popover/Popover.tsx
 *
 * @param param0
 * @returns
 */
export const PopoverTriggerButton = forwardRef<HTMLButtonElement, IBaseButton>(
  (
    {
      children,
      color = "primary",
      variant = "plain",
      width = "hug-content",
      size = "medium",
      weight = "regular",
      contentType = "text",
      type = "button",
      ...props
    }: IBaseButton,
    ref,
  ) => {
    return (
      <PopoverTrigger
        className={clsx(
          styles.baseButton,
          styles[`color-${color}`],
          styles[`width-${width}`],
          styles[`size-${size}`],
          styles[`weight-${weight}`],
          styles[`variant-${variant}`],
          styles[`contentType-${contentType}`],
        )}
        type={type}
        ref={ref}
        {...props}
      >
        {children}
      </PopoverTrigger>
    );
  },
);

PopoverTriggerButton.displayName = "Button";
