import { z } from "zod";

export const address = z.object({
  addressLine1: z.string(),
  addressLine2: z.string().nullish(),
  city: z.string(),
  postalCode: z.string(),
  additionalInformation: z.string().nullish(),
});
export type IAddress = z.infer<typeof address>;

const coordinatesSchema = z.object({
  longitude: z.number().min(-180).max(180),
  latitude: z.number().min(-90).max(90),
});
export const addressWithCoordinates = address.extend({
  coordinates: coordinatesSchema,
});
export type IAddressWithCoordinates = z.infer<typeof addressWithCoordinates>;
