import { useEffect, useState } from "react";
import type { Room, RoomMember } from "matrix-js-sdk";
import { RoomEvent } from "matrix-js-sdk";

const isSystemUser = (member: RoomMember) =>
  member.userId.startsWith("@patient-api-medoma-matrix-admin-user:");

const roomMembersIds = (room: Room) =>
  room
    .getJoinedMembers()
    .filter((member) => !isSystemUser(member))
    .map((member) => member.userId);

const lastReadEventForUser = (room: Room, userId: string) => {
  const lastReadEventId = room.getEventReadUpTo(userId);
  if (!lastReadEventId) return undefined;

  const event = room.findEventById(lastReadEventId);
  if (!event) return undefined;

  const userReceipt = room
    .getReceiptsForEvent(event)
    .find((receipt) => receipt.userId === userId);
  if (!userReceipt) return undefined;

  const date = new Date(userReceipt?.data.ts);

  return {
    userId,
    eventId: lastReadEventId,
    timestamp: date,
  };
};

const lastReadEventPerUser = (room: Room | null) => {
  if (!room) return undefined;
  return roomMembersIds(room).map((memberId) => {
    return lastReadEventForUser(room, memberId);
  });
};

export const useLastReadEventPerUser = ({
  getRoom,
}: {
  getRoom: () => Room | null;
}) => {
  const [lastReadEventIds, setLastReadEventIds] = useState<
    ReturnType<typeof lastReadEventPerUser>
  >(() => lastReadEventPerUser(getRoom()));

  useEffect(() => {
    const room = getRoom();
    if (!room) return;
    const updateLastReadEventIds = () => {
      setLastReadEventIds(lastReadEventPerUser(room));
    };
    room.on(RoomEvent.Receipt, updateLastReadEventIds);
    return () => {
      room.off(RoomEvent.Receipt, updateLastReadEventIds);
    };
  }, [getRoom]);

  return lastReadEventIds;
};

export const lastEventReadByUser = (
  lastReadEventPerUser: ReturnType<typeof useLastReadEventPerUser>,
  userId: string,
) => {
  if (!lastReadEventPerUser) return undefined;
  return lastReadEventPerUser.find((member) => member?.userId === userId);
};

export const lastEventReadByUserOtherThanUserId = (
  lastReadEventPerUser: ReturnType<typeof useLastReadEventPerUser>,
  userId: string | undefined,
) => {
  if (!lastReadEventPerUser || !userId) return undefined;
  const lastReadEventPerRelevantMember = lastReadEventPerUser.filter(
    (member) => {
      if (!member) return false;
      return userId !== member.userId;
    },
  );
  return [
    ...lastReadEventPerRelevantMember.sort((a, b) => {
      if (!a?.timestamp) return 1;
      if (!b?.timestamp) return -1;
      return a.timestamp.getTime() - b.timestamp.getTime();
    }),
  ].findLast((event) => event?.timestamp);
};
