import { Outlet, useLocation } from "react-router-dom";
import styles from "./index.module.scss";
import { Menu } from "./menu";
import ChatContext from "@chat/ChatContext";
import { applicationSchema, useMatrixClient } from "@chat/matrixClient";
import { useEffect } from "react";
import { getCareJwt } from "./api/chat";
import { ClientEvent } from "matrix-js-sdk";
import clsx from "clsx";
import { notificationApi } from "./api/apiClient";
import { usePatient } from "@/api/patient";
import { UnreadMessageBannerContent } from "@/components/UnreadMessageBannerContent";
import { useHasUnreadNotifications } from "@chat/useUnreadNotificationCounts";
import { Banner } from "./components/Banner";
import { useUnreadNotifications } from "@chat/useUnreadNotifications";
import { useCareSSEHandlers } from "./utils/sseHandlers";
import * as Sentry from "@sentry/react";

const ChatProvider = ({ children }: { children: JSX.Element }) => {
  const matrixClient = useMatrixClient(
    applicationSchema.Values.Care,
    getCareJwt,
    true,
  );

  // Use debug chat
  useEffect(() => {
    if (matrixClient === null) {
      return;
    }

    if (import.meta.env.VITE_DEBUG_CHAT) {
      matrixClient.on(ClientEvent.Event, console.log);

      return () => {
        matrixClient.off(ClientEvent.Event, console.log);
      };
    }
  }, [matrixClient]);

  const unreadNotifications = useUnreadNotifications(matrixClient);

  return (
    <ChatContext.Provider
      value={{ matrixClient, unreadNotifications, chatEnabled: true }}
    >
      {children}
    </ChatContext.Provider>
  );
};

const useRegisterForPushNotifications = () => {
  useEffect(() => {
    // @ts-expect-error PUSHY_TOKEN is injected on window object from native app
    const pushyToken: string | undefined = window.PUSHY_TOKEN;
    // @ts-expect-error DEVICE_NAME is injected on window object from native app
    const deviceName: string | undefined = window.DEVICE_NAME;
    if (!pushyToken) {
      console.warn("No Pushy token found, skipping registration.");
    } else {
      notificationApi.put(`/device`, {
        deviceName,
        registrationToken: pushyToken,
      });
    }
  }, []);
};

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
    };
  }
}
const useNotifyWebAppIsReady = () => {
  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("app:ready");
    }
  }, []);
};

const InnerComponent = () => {
  const { data: patient } = usePatient();

  useEffect(() => {
    if (patient) {
      Sentry.setUser({
        username: `${patient.id}`,
      });
    }
  }, [patient]);

  const hasUnreadNotification = useHasUnreadNotifications({
    roomName: patient?.id,
  });
  const location = useLocation();
  const isInChat = location.pathname.endsWith("chat");
  return (
    <>
      <div className={styles.leftPanel}>
        <Banner isVisible={!isInChat && hasUnreadNotification} isLinkTo="/chat">
          <UnreadMessageBannerContent
            hasUnreadNotification={hasUnreadNotification}
          />
        </Banner>
        <main className={styles.main}>
          <Outlet />
        </main>
      </div>
      <Menu hasUnreadNotification={hasUnreadNotification} />
    </>
  );
};

export const Index = () => {
  useNotifyWebAppIsReady();
  useRegisterForPushNotifications();
  useCareSSEHandlers();

  return (
    <div className={clsx([styles.app, styles.componentVariableOverrides])}>
      <ChatProvider>
        <InnerComponent />
      </ChatProvider>
    </div>
  );
};
