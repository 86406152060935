export const standardizePhoneNumber = (phoneNumber: string) => {
  const spacelessPhoneNumber = phoneNumber.replaceAll(" ", "");
  const standardizedPhoneNumber =
    spacelessPhoneNumber.startsWith("0") &&
    !spacelessPhoneNumber.startsWith("00")
      ? `0046${spacelessPhoneNumber.substring(1)}`
      : spacelessPhoneNumber;
  return standardizedPhoneNumber;
};

export const localizePhoneNumber = (phoneNumber: string) => {
  return phoneNumber.startsWith("0046")
    ? `0${phoneNumber.substring(4)}`
    : phoneNumber;
};
