import clsx from "clsx";
import styles from "./CircledInitials.module.scss";
import { getInitials } from "./initialsUtils";

interface ICircledInitials {
  name: string;
  size?: "small" | "medium" | "large";
}
function CircledInitials({ name, size = "small" }: ICircledInitials) {
  return (
    <div className={clsx(styles.circledInitials, styles[size])}>
      {getInitials(name)}
    </div>
  );
}

export default CircledInitials;
