import { differenceInMilliseconds } from "date-fns";
import type { MatrixClient } from "matrix-js-sdk";
import { useCallback, useEffect, useState } from "react";

const TYPING_THROTTLE = 5_000; // Don't sent typing notifications more often than this
const TYPING_TIMEOUT = 20_000; // After this duration, the user is considered to have stopped typing

export const useSendTypingNotification = ({
  matrixClient,
  roomId,
}: {
  matrixClient: MatrixClient | null;
  roomId: string | null;
}) => {
  const sendTypingNotification = useCallback(
    ({ isTyping }: { isTyping: boolean }) => {
      if (!matrixClient || !roomId) return;

      matrixClient.sendTyping(roomId, isTyping, TYPING_TIMEOUT);
      setLastSentTypingNotification(new Date());
    },
    [matrixClient, roomId],
  );

  const updateTypingState = ({
    event,
    pendingMessage,
  }: {
    event: React.ChangeEvent<HTMLTextAreaElement>;
    pendingMessage: string;
  }) => {
    const valueBeforeKeyStroke = pendingMessage;
    const valueAfterKeyStroke = event.currentTarget.value;

    const now = new Date();

    const wasTypingBefore = valueBeforeKeyStroke.trim() !== "";
    const isTyping = valueAfterKeyStroke.trim() !== "";

    if (
      // Important event, bypass throttling
      wasTypingBefore !== isTyping ||
      // More than 5 seconds since we last sent a typing notification
      (lastSentTypingNotification &&
        Math.abs(differenceInMilliseconds(lastSentTypingNotification, now)) >
          TYPING_THROTTLE)
    ) {
      sendTypingNotification({ isTyping });
    }
  };

  const [lastSentTypingNotification, setLastSentTypingNotification] =
    useState<Date | null>(null);

  useEffect(() => {
    setLastSentTypingNotification(null);
  }, [roomId]);

  const sendNoLongerTypingNotification = useCallback(() => {
    sendTypingNotification({ isTyping: false });
  }, [sendTypingNotification]);

  return { sendNoLongerTypingNotification, updateTypingState };
};
