import { CardHeading } from "./cardHeading";
import styles from "./groupCard.module.scss";
import clsx from "clsx";
import { Text } from "@components/Text/Text";
import { AssigneeList } from "./assigneeList";
import {
  categorySchema,
  type IActivityOccurrence,
  type IGroupOfHomeVisitActivityOccurrence,
} from "@models/activities";
import { timeOfDaySchema } from "@models/activities";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { activityCategoryDictionary } from "@/api/activities";
import { useLingui } from "@lingui/react";

type IOccurrenceTitle = Pick<IActivityOccurrence, "title">;
export const GroupCard = ({
  group,
}: {
  group: Pick<
    IGroupOfHomeVisitActivityOccurrence,
    "assignees" | "category" | "start" | "end" | "timeOfDay"
  > & { occurrences: ReadonlyArray<IOccurrenceTitle> };
}) => {
  const { _ } = useLingui();
  const { start, end, timeOfDay } = group;

  const formattedTimespan = (binding: string) => {
    return start !== undefined && end !== undefined
      ? `${i18n.date(start, { timeStyle: "short" })}${binding}${i18n.date(end, { timeStyle: "short" })}`
      : null;
  };

  const timespan = formattedTimespan(` ${t`och`} `);

  const groupWithAssignedEmployees = {
    ...group,
    assignees: group.assignees.filter(({ employee }) => employee),
  };
  const groupHasAssignedEmployees =
    groupWithAssignedEmployees.assignees.length > 0;

  const timeSpanText =
    timeOfDay === timeOfDaySchema.Values.Any
      ? t`Under dagen`
      : start && end
        ? t`Mellan ${timespan}`
        : null;

  return (
    <article className={clsx(styles.groupCard)}>
      <CardHeading
        category={group.category}
        title={_(activityCategoryDictionary[categorySchema.Values.HomeVisit])}
        formattedTimespan={timeSpanText}
      />
      <Text element="span" weight="medium">
        {group.occurrences.map((occurrence) => occurrence.title).join(", ")}
      </Text>
      {groupHasAssignedEmployees ? (
        <AssigneeList assignees={groupWithAssignedEmployees.assignees} />
      ) : null}
    </article>
  );
};
