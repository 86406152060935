import clsx from "clsx";
import styles from "./InfoPanel.module.scss";

type IInfoPanel = {
  children: string | JSX.Element;
  type: "error" | "info";
};

export const InfoPanel = ({ children, type }: IInfoPanel) => {
  return (
    <section className={clsx(styles.infoPanel, styles[`type-${type}`])}>
      {children}
    </section>
  );
};
