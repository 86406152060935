import styles from "./ErrorMessage.module.scss";
import clsx from "clsx";
import * as Sentry from "@sentry/react";

/**
 * Use **padding** sparingly, prefer to use padding in parent (layout) component
 */
const ErrorMessage = ({
  message,
  weight = "regular",
  padding = 0,
}: {
  message: string;
  weight?: "regular" | "bold";
  padding?: number;
}) => {
  Sentry.captureException(new Error(`Error message was displayed: ${message}`));
  return (
    <p
      className={clsx([styles.errorMessage, styles[`weight-${weight}`]])}
      style={{ padding }}
      aria-live="polite"
    >
      {message}
    </p>
  );
};

export default ErrorMessage;
