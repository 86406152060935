import styles from "./Portrait.module.scss";
import CircledInitials from "../CircledInitials/CircledInitials";
import clsx from "clsx";
import { useEffect, useState } from "react";

interface IPortrait {
  name: string;
  photoUrl: string | null | undefined;
  size?: "small" | "medium" | "large";
}
const Portrait = ({ name, photoUrl, size = "medium" }: IPortrait) => {
  const [hasValidPhotoUrl, setHasValidPhotoUrl] = useState<boolean>();

  useEffect(() => {
    setHasValidPhotoUrl(Boolean(photoUrl));
  }, [photoUrl]);

  return hasValidPhotoUrl ? (
    <img
      className={clsx(styles.profilePicture, styles[`size-${size}`])}
      src={photoUrl ?? undefined}
      alt={name}
      onError={() => setHasValidPhotoUrl(false)}
    />
  ) : (
    <CircledInitials name={name} size={size} />
  );
};

export default Portrait;
