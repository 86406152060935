import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { useEffect, useState } from "react";
import { retrieveBrowserLocale } from "./utils/i18n";
import { Helmet } from "react-helmet-async";
import { msg } from "@lingui/macro";

async function dynamicActivate(locale: "en" | "sv" | "de") {
  const { messages } = await import(`./locales/${locale}.ts`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}

export const I18nApp = ({ children }: { children: React.ReactNode }) => {
  const browserLocale = retrieveBrowserLocale();

  const [translationActive, setTranslationActive] = useState(false);
  useEffect(() => {
    dynamicActivate(browserLocale).then(() => setTranslationActive(true));
  }, [browserLocale]);
  // Not sure if controlling `translationActive` state is necessary
  // but it stops I18nProvider from rendering with `null`
  // which causes it to render an informative message that could be disturbing
  if (!translationActive) return null;
  const title = i18n._(msg`Medoma för patient`);
  return (
    <I18nProvider i18n={i18n}>
      <>
        <Helmet>
          <html lang={browserLocale} />
          <title>{title}</title>
        </Helmet>
        {children}
      </>
    </I18nProvider>
  );
};
